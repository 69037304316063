import type { FC } from 'react'

import type { BenefitsWithImageType } from '@Types/blocks/benefitsWithImage'
import type { ExtendBlocksType } from '@Types/blocks/blocks'

import Card from './components/Card'
import { Container, ScrollWrapper } from './styles'

type Props = {
  data: ExtendBlocksType
}

export const BenefitsWithImages: FC<Props> = ({ data }) => {
  const { benefits } = data as BenefitsWithImageType

  return (
    <ScrollWrapper>
      <Container>
        {benefits.map(item => (
          <Card itemId={item} key={item} />
        ))}
      </Container>
    </ScrollWrapper>
  )
}
